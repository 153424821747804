import { common } from '@local/web-design-system-2/dist/utils/common.styles';
import type {
    WorkspaceRoleOptionalResponse,
    UserWorkspaceResponse,
} from '@local/workspaces/dist/apiClients/GENERATED_workspaceClientEndpoints';
import { fetchWorkspaces } from '@local/workspaces/dist/apiClients/workspaceClientEndpoints';
import {
    getHubUrlForCurrentOrg,
    getOrgUuidFromParams,
} from '@local/workspaces/dist/components/OrgRouteGuard/OrgRouteGuard';
import type { AutocompleteRenderGetTagProps } from '@mui/material/Autocomplete';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Skeleton from '@mui/material/Skeleton';
import TextField from '@mui/material/TextField';
import React, { useState, useEffect, Fragment } from 'react';
import { useParams } from 'react-router-dom';

import { ENTER_WORKSPACE, NO_WORKSPACES_FOUND } from 'src/strings';

interface InfiniteScrollWorkspaceAdminMultiSelectorProps {
    onChange: (selectedOptions: WorkspaceRoleOptionalResponse[]) => void;
    selectedWorkspaceList: WorkspaceRoleOptionalResponse[];
    onError: (error: any) => void;
    excludeWorkspaces: UserWorkspaceResponse[] | WorkspaceRoleOptionalResponse[] | undefined;
}

export const InfiniteScrollWorkspaceAdminMultiSelector: React.FC<
    InfiniteScrollWorkspaceAdminMultiSelectorProps
> = ({ onChange, selectedWorkspaceList, onError, excludeWorkspaces }) => {
    const params = useParams();
    const [searchTerm, setSearchTerm] = useState('');
    const [page, setPage] = useState(0);
    const [allWorkspaces, setAllWorkspaces] = useState<WorkspaceRoleOptionalResponse[]>([]);
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);

    const { data, isFetching, isError, error } = fetchWorkspaces({
        isAdmin: true,
        hubUrl: getHubUrlForCurrentOrg(),
        orgId: getOrgUuidFromParams(params),
        limit: 20,
        offset: page * 20,
        filter: {
            name: debouncedSearchTerm,
        },
        orderBy: 'asc:name',
    });

    useEffect(() => {
        if (isError) {
            onError(error);
        }
    }, [isError, error, onError]);

    useEffect(() => {
        if (data?.results) {
            setAllWorkspaces((prevWorkspaces) => {
                // Map values to prevent duplication cases.
                const workspaceMap = new Map(
                    prevWorkspaces.map((workspace) => [workspace.id, workspace]),
                );
                data.results.forEach((workspace) => {
                    workspaceMap.set(workspace.id, workspace);
                });
                return Array.from(workspaceMap.values());
            });
        }
    }, [data]);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedSearchTerm(searchTerm);
        }, 350);

        return () => {
            clearTimeout(handler);
        };
    }, [searchTerm]);

    const handleScroll = (event: React.UIEvent<HTMLElement>) => {
        const bottom =
            event.currentTarget.scrollHeight - event.currentTarget.scrollTop ===
            event.currentTarget.clientHeight;
        if (bottom && data?.links?.next) {
            setPage((prevPage) => prevPage + 1);
        }
    };

    const handleTagDelete = (option: WorkspaceRoleOptionalResponse) => {
        const val = selectedWorkspaceList.filter((entry) => entry !== option);
        onChange(val);
    };

    const renderTags = (
        value: WorkspaceRoleOptionalResponse[],
        getTagProps: AutocompleteRenderGetTagProps,
    ) =>
        value.map((option: WorkspaceRoleOptionalResponse, index: number) => {
            const tagProps = getTagProps({ index });
            return (
                <Chip
                    label={option.name}
                    {...tagProps}
                    key={option.id}
                    onDelete={() => handleTagDelete(option)}
                />
            );
        });

    const workspaceIds = excludeWorkspaces?.map((workspace) => workspace.id);
    // Kinda hacky. Correct way to do this would be to have a kind of exclusionary query param for the user id passed into the fetchWorkspaces call.
    // This will only remove workspaces existing on a current given page.
    const filteredData = allWorkspaces.filter((workspace) => !workspaceIds?.includes(workspace.id));

    return (
        <Box sx={(theme) => ({ maxWidth: '75%', minWidth: theme.spacing(25) })}>
            <Autocomplete
                automation-id="infinite-scroll-multi-select-workspace"
                size="small"
                multiple
                loading={isFetching || debouncedSearchTerm !== searchTerm}
                ListboxProps={{
                    sx: (theme) => ({ ...common(theme).thinScrollBar }),
                    onScroll: handleScroll,
                }}
                filterOptions={(x) => x} // Disable native filtering in favour of server-side filtering
                options={filteredData ?? []}
                getOptionLabel={(workspace) =>
                    typeof workspace === 'string' ? workspace : workspace.name
                }
                getOptionKey={(workspace) =>
                    typeof workspace === 'string' ? workspace : workspace.id
                }
                onChange={(event, newValue: WorkspaceRoleOptionalResponse[]) => {
                    onChange(newValue);
                }}
                inputValue={searchTerm}
                onInputChange={(event, newInputValue, reason) => {
                    if (reason === 'reset' || reason === 'clear') {
                        return;
                    }
                    setSearchTerm(newInputValue);
                    setPage(0);
                    setAllWorkspaces([]);
                }}
                renderInput={(props) => (
                    <TextField
                        {...props}
                        placeholder={ENTER_WORKSPACE}
                        automation-id="infinite-scroll-input"
                        variant="outlined"
                    />
                )}
                renderTags={renderTags}
                noOptionsText={NO_WORKSPACES_FOUND}
                value={selectedWorkspaceList}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                disableCloseOnSelect
                renderOption={(
                    props,
                    option,
                    { index }, // Provides loading ux/feedback when fetching more data at the bottom of the list.
                ) => (
                    // We have warnings saying that key spreading is not allowed.
                    // But the typing is wrong for the props (https://github.com/mui/material-ui/issues/39833).
                    // Where we can't extract key without a typescript error. So the warning will be ignored for now.
                    <Fragment key={option.id}>
                        <li {...props}>{option.name}</li>
                        {index === filteredData.length - 1 && isFetching && (
                            <li key="loading">
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'left',
                                        paddingLeft: '16px',
                                        paddingRight: '16px',
                                    }}
                                >
                                    <Skeleton
                                        width="100%"
                                        height="20px"
                                        variant="rectangular"
                                        animation="wave"
                                    />
                                </Box>
                            </li>
                        )}
                    </Fragment>
                )}
            />
        </Box>
    );
};
