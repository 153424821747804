import type { BreadcrumbSegment } from '@local/content-area/dist/Breadcrumbs/BreadcrumbsContext';
import { useBreadcrumbs } from '@local/content-area/dist/Breadcrumbs/BreadcrumbsContext';
import { getAdminFromBentleyAccessToken } from '@local/login';
import { NotFoundSvg } from '@local/svgs/dist/svg/NotFoundSvg';
import type { ManageUser } from '@local/user-manage/dist/apiClients/entities';
import { useGetUsersQuery } from '@local/user-manage/dist/apiClients/manageClient/enhancedUserManageMiddlewareBentley';
import EmptyState from '@local/web-design-system-2/dist/components/EmptyState/EmptyState';
import { getOrgUuidFromParams } from '@local/workspaces/dist/components/OrgRouteGuard/OrgRouteGuard';
import { Restricted } from '@local/workspaces/dist/components/Restricted/Restricted';
import { setDocumentTitle } from '@local/workspaces/dist/utils/setDocumentTitle';
import CircularProgress from '@mui/material/CircularProgress';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import {
    NOT_FOUND_MESSAGE,
    RESTRICTED_NO_PERMISSION_USERS,
    USER_NOT_FOUND_TITLE,
    EDIT_USER_TITLE,
    ADMINISTRATORS_TITLE,
} from 'src/strings';

import { PageContent } from '../../components/pageContent/PageContent';
import { UsersWorkspaces } from './workspacePermissions/UsersWorkspaces';

export function BentleyEditUserPage() {
    setDocumentTitle(EDIT_USER_TITLE);
    const params = useParams();
    const isAdmin = getAdminFromBentleyAccessToken();
    const { data: evoUsersList, isLoading } = useGetUsersQuery({
        org_id: getOrgUuidFromParams(params),
    });

    const userDetails = evoUsersList?.users.find((user: ManageUser) => user.id === params.userUuid);

    const { breadcrumbs, setBreadcrumbs } = useBreadcrumbs();
    useEffect(() => {
        const segments: BreadcrumbSegment[] = userDetails
            ? [
                  {
                      name: ADMINISTRATORS_TITLE,
                      path: `/${params.orgUuid}/${ADMINISTRATORS_TITLE.toLowerCase()}`,
                  },
                  { name: `${userDetails.given_name} ${userDetails.family_name}` },
              ]
            : [
                  {
                      name: ADMINISTRATORS_TITLE,
                      path: `/${params.orgUuid}/${ADMINISTRATORS_TITLE.toLowerCase()}`,
                  },
              ];
        if (userDetails !== undefined || !breadcrumbs.length) {
            setBreadcrumbs(segments);
        }
    }, [userDetails]);

    if (!isAdmin) {
        return <Restricted message={RESTRICTED_NO_PERMISSION_USERS} />;
    }

    return (
        <PageContent
            pageTitle={
                userDetails ? (
                    `${userDetails.given_name} ${userDetails.family_name}`
                ) : (
                    <Skeleton variant="rectangular" height={36} width="33%" />
                )
            }
            pageSubTitle={
                userDetails ? (
                    `${userDetails.email}`
                ) : (
                    <Skeleton variant="rectangular" height={24} width="22%" />
                )
            }
            enablePageTitle={false}
        >
            <UsersWorkspaces />
            {isLoading && (
                <Stack
                    sx={{ height: 'calc(100vh - 218px)', width: '100%' }}
                    justifyContent="center"
                    alignItems="center"
                    position="relative"
                >
                    <CircularProgress />
                </Stack>
            )}
            {!isLoading && !userDetails && (
                <EmptyState
                    sx={{
                        padding: 2,
                        paddingTop: 0,
                        svg: {
                            width: '550px',
                        },
                    }}
                    image={<NotFoundSvg />}
                    title={USER_NOT_FOUND_TITLE}
                    message={NOT_FOUND_MESSAGE}
                />
            )}
        </PageContent>
    );
}
