import type { BreadcrumbSegment } from '@local/content-area/dist/Breadcrumbs/BreadcrumbsContext';
import { useBreadcrumbs } from '@local/content-area/dist/Breadcrumbs/BreadcrumbsContext';
import { useTrace } from '@local/web-design-system-2/dist/utils/trace';
import { useGetOrganizationSettingsQuery } from '@local/workspaces/dist/apiClients/enhancedWorkspaceMiddleware';
import {
    getHubUrlForCurrentOrg,
    getOrgUuidFromParams,
} from '@local/workspaces/dist/components/OrgRouteGuard/OrgRouteGuard';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { WorkspaceListTable, ErrorMessage } from 'src/pages/adminSettings/WorkspaceListTable';
import {
    MACHINE_LEARNING,
    ML_DESC_CONTACT_US,
    ML_DESC_TEXT_1,
    ML_DESC_TEXT_2,
    ML_HEADER_TEXT,
    SETTINGS_TITLE,
} from 'src/strings';

export function AdminSettingsPage() {
    const { evouiWsMlEnablement } = useFlags();
    const params = useParams();
    const [tabSelection, setTabSelection] = useState(MACHINE_LEARNING);
    const handleTabSelection = (_e: React.SyntheticEvent, newValue: string) => {
        setTabSelection(newValue);
    };
    const { data, isFetching, isError } = useGetOrganizationSettingsQuery({
        hubUrl: getHubUrlForCurrentOrg(),
        orgId: getOrgUuidFromParams(params),
    });
    const orgMlEnable = data?.settings?.ml_enabled ?? false;

    const applyTrace = useTrace('admin-settings');
    const { setBreadcrumbs } = useBreadcrumbs();
    const segments: BreadcrumbSegment[] = [
        {
            name: SETTINGS_TITLE,
        },
    ];
    useEffect(() => {
        setBreadcrumbs(segments);
    }, []);

    return (
        // TODO: Remove Box sx props styling once the base component <ContentScaffolding> is migrated to MUI
        <Box
            sx={{
                bgcolor: 'background.default',
                margin: '-24px',
                height: '100%',
            }}
        >
            <Stack
                direction="row"
                sx={{
                    justifyContent: 'space-between',
                    alignContent: 'center',
                    p: '48px 32px 32px 32px',
                }}
            >
                <Stack>
                    <Typography variant="h3">{SETTINGS_TITLE}</Typography>
                    {evouiWsMlEnablement && (
                        <Typography variant="h5" color="textSecondary">
                            {tabSelection}
                        </Typography>
                    )}
                </Stack>
                <Box alignContent="center">
                    <Tabs value={tabSelection} onChange={handleTabSelection}>
                        {evouiWsMlEnablement && (
                            <Tab
                                label={
                                    <Typography variant="button" sx={{ textTransform: 'none' }}>
                                        {MACHINE_LEARNING}
                                    </Typography>
                                }
                                value={MACHINE_LEARNING}
                                automation-id={applyTrace('ml-tab')}
                            />
                        )}
                    </Tabs>
                </Box>
            </Stack>
            {evouiWsMlEnablement &&
                tabSelection === MACHINE_LEARNING &&
                !isError &&
                !isFetching && (
                    <>
                        <Stack spacing={2} pl={4} pb={4} sx={{ width: '75%' }}>
                            <Typography variant="h5">
                                {orgMlEnable ? ML_HEADER_TEXT.enabled : ML_HEADER_TEXT.disabled}
                            </Typography>
                            <Typography variant="body1">
                                {orgMlEnable ? ML_DESC_TEXT_1.enabled : ML_DESC_TEXT_1.disabled}
                                <Link
                                    href="https://www.seequent.com/contact-us/"
                                    target="_blank"
                                    variant="body1"
                                    underline="always"
                                    color="inherit"
                                >
                                    {ML_DESC_CONTACT_US}
                                </Link>
                            </Typography>
                            <Typography variant="body1">
                                {orgMlEnable ? ML_DESC_TEXT_2.enabled : ML_DESC_TEXT_2.disabled}
                            </Typography>
                        </Stack>
                        <WorkspaceListTable orgMlEnable={orgMlEnable} />
                    </>
                )}
            {isError && <ErrorMessage />}
        </Box>
    );
}
