import { useMessagesContext } from '@local/messages-wds2/dist/MessagesContext';
import { trackError } from '@local/metrics';
import Remove from '@local/web-design-system-2/dist/icons/Remove';
import { useDeleteUserRoleAdminMutation } from '@local/workspaces/dist/apiClients/enhancedWorkspaceMiddleware';
import type { UserWorkspaceResponse } from '@local/workspaces/dist/apiClients/GENERATED_workspaceClientEndpoints';
import {
    getHubUrlForCurrentOrg,
    getOrgUuidFromParams,
} from '@local/workspaces/dist/components/OrgRouteGuard/OrgRouteGuard';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import { useParams } from 'react-router-dom';

import { REMOVE_WORKSPACE_MESSAGE } from 'src/strings';

export const WorkspaceActions = ({ workspace }: { workspace: UserWorkspaceResponse }) => {
    const { addMessage } = useMessagesContext();
    const [deleteUserAdmin, { isLoading }] = useDeleteUserRoleAdminMutation();
    const params = useParams();

    const handleButtonClick = (event: React.MouseEvent) => {
        event.stopPropagation();

        deleteUserAdmin({
            hubUrl: getHubUrlForCurrentOrg(),
            orgId: getOrgUuidFromParams(params),
            workspaceId: workspace.id,
            userId: params.userUuid ?? '', // its not possible to get to this page, and have this be undefined
        })
            .unwrap()
            .catch((deleteError) => {
                trackError(
                    'Error removing user role from workspace, ',
                    JSON.stringify(deleteError),
                );
                addMessage({
                    message: REMOVE_WORKSPACE_MESSAGE,
                    severity: 'error',
                });
            });
    };

    if (isLoading) {
        return (
            <span style={{ float: 'right', padding: '7.5px', paddingRight: '10px' }}>
                <CircularProgress size="20px" automation-id="remove-workspace-progress" />
            </span>
        );
    }

    return (
        <span>
            <IconButton color="secondary" onClick={handleButtonClick}>
                <Remove color="inherit" />
            </IconButton>
        </span>
    );
};
