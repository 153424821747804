import { ErrorScreen } from '@local/svgs/dist/pageState/ErrorScreen';
import { NotFoundSvg } from '@local/svgs/dist/svg/NotFoundSvg';
import { SearchSvg } from '@local/svgs/dist/svg/SearchSvg';
import EmptyState from '@local/web-design-system-2/dist/components/EmptyState/EmptyState';
import { ListItem } from '@local/web-design-system-2/dist/components/GenericListing/ListItem';
import type { FieldDefinition } from '@local/web-design-system-2/dist/components/GenericListing/types';
import { common } from '@local/web-design-system-2/dist/utils/common.styles';
import { useListUserWorkspacesAdminQuery } from '@local/workspaces/dist/apiClients/enhancedWorkspaceMiddleware';
import type { UserWorkspaceResponse } from '@local/workspaces/dist/apiClients/GENERATED_workspaceClientEndpoints';
import {
    getHubUrlForCurrentOrg,
    getOrgUuidFromParams,
} from '@local/workspaces/dist/components/OrgRouteGuard/OrgRouteGuard';
import { WorkspaceSearch } from '@local/workspaces/dist/components/WorkspaceSearch/WorkspaceSearch';
import {
    NETWORK_ERROR_DESCR,
    NETWORK_ERROR_TITLE,
    SEARCH,
    SEARCH_WORKSPACES_NO_RESULTS,
} from '@local/workspaces/dist/strings';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack/Stack';
import Table from '@mui/material/Table/Table';
import TableBody from '@mui/material/TableBody/TableBody';
import TableCell from '@mui/material/TableCell/TableCell';
import TableContainer from '@mui/material/TableContainer/TableContainer';
import TableHead from '@mui/material/TableHead/TableHead';
import TablePagination from '@mui/material/TablePagination/TablePagination';
import TableRow from '@mui/material/TableRow/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel/TableSortLabel';
import type { ChangeEvent } from 'react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { DEFAULT_PAGE_SIZE_OPTIONS } from 'src/utils/pagination';

import { USER_NO_WORKSPACES, USER_NO_WORKSPACES_MESSAGE } from '../../../strings';
import { CustomScaledTableSkeleton } from './CustomScaledTableSkeleton';
import { WorkspaceActions } from './RemoveFromWorkspace';
import { WorkspaceLinkCell } from './WorkspaceLinkCell';
import { WorkspaceRoleAssigner } from './WorkspaceRoleAssigner';
import { WorkspaceRoleCell } from './WorkspaceRoleCell';

export function UsersWorkspaces() {
    const params = useParams();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [searchTerm, setSearchTerm] = useState('');
    const [orderBy, setOrderBy] = useState<keyof UserWorkspaceResponse>('name');
    const [order, setOrder] = useState<'asc' | 'desc'>('asc');

    // This should never be null as the page will redirect you before getting here
    const userId = params.userUuid ?? '';

    const handleChangePage = (
        _: React.MouseEvent<HTMLButtonElement> | null,
        pageNumber: number,
    ) => {
        setPage(pageNumber);
    };

    const handleChangeRowsPerPage = (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSearch = (search: string) => {
        setSearchTerm(search);
        setPage(0);
    };

    const handleRequestSort = (property: keyof UserWorkspaceResponse) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const itemDef: FieldDefinition<UserWorkspaceResponse>[] = [
        {
            key: 'name',
            label: 'Workspaces',
            component: WorkspaceLinkCell,
        },
        {
            key: 'user_role',
            label: 'User Permissions',
            component: WorkspaceRoleCell,
        },
    ];

    const renderRow = (object: UserWorkspaceResponse) => (
        <ListItem
            key={object.id}
            item={{ ...object, user_id: userId }}
            fields={itemDef}
            actions={<WorkspaceActions workspace={object} />}
        />
    );

    const { data, isFetching, isError } = useListUserWorkspacesAdminQuery({
        hubUrl: getHubUrlForCurrentOrg(),
        orgId: getOrgUuidFromParams(params),
        userId,
        limit: rowsPerPage,
        offset: page * rowsPerPage,
        filter: {
            name: searchTerm,
        },
        orderBy: `${order}:${orderBy}`,
    });

    const results = data?.results ?? [];

    const emptyResults = !isFetching && results.length === 0 && searchTerm.length === 0;
    const emptySearchResults = !isFetching && results.length === 0 && searchTerm.length > 0;

    if (isError) {
        return <ErrorScreen msg={NETWORK_ERROR_TITLE} details={NETWORK_ERROR_DESCR} />;
    }

    let content;
    if (emptyResults) {
        content = (
            <Box>
                <EmptyState
                    title={USER_NO_WORKSPACES}
                    message={USER_NO_WORKSPACES_MESSAGE}
                    image={<NotFoundSvg />}
                />
            </Box>
        );
    } else if (emptySearchResults) {
        content = (
            <EmptyState
                title={SEARCH_WORKSPACES_NO_RESULTS}
                message=""
                image={<SearchSvg />}
                sx={{
                    paddingTop: '80px',
                }}
                titleSx={{
                    color: (theme) => theme.palette.text.primary,
                    lineHeight: (theme) => theme.typography.h2.lineHeight,
                    fontWeight: '600',
                    paddingTop: '6px',
                    paddingBottom: '6px',
                }}
                messageSx={{
                    paddingTop: '0',
                    paddingBottom: '6px',
                }}
            />
        );
    } else {
        content = (
            <Box sx={{ width: '100%' }}>
                <TableContainer sx={{ height: '100%' }}>
                    <Table
                        size="small"
                        stickyHeader
                        sx={{ overflowY: 'auto' }}
                        aria-labelledby="tableTitle"
                    >
                        <TableHead>
                            <TableRow>
                                {itemDef.map((headCell) => (
                                    <TableCell key={String(headCell.key)} align="left">
                                        <TableSortLabel
                                            active={orderBy === headCell.key}
                                            direction={orderBy === headCell.key ? order : 'asc'}
                                            onClick={() =>
                                                handleRequestSort(
                                                    headCell.key as keyof UserWorkspaceResponse,
                                                )
                                            }
                                        >
                                            {headCell.label}
                                        </TableSortLabel>
                                    </TableCell>
                                ))}
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <WorkspaceRoleAssigner workspaces={results} />
                            {isFetching && <CustomScaledTableSkeleton rows={rowsPerPage} />}
                            {!isFetching && results.length !== 0 && results.map(renderRow)}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        );
    }

    return (
        <Box
            sx={{
                alignContent: 'flex-start',
                position: 'sticky',
            }}
        >
            <Grid
                sx={(theme) => ({
                    ...common(theme).thinScrollBar,
                    height: `calc(100vh - ${theme.spacing(36.5)})`,
                    overflow: 'auto',
                    '@media (max-width: 1199px)': {
                        height: '550px',
                    },
                })}
            >
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={(theme) => ({
                        paddingRight: theme.spacing(2),
                        paddingLeft: theme.spacing(2),
                    })}
                >
                    <TablePagination
                        component="div"
                        count={data?.links.total || 0}
                        page={page}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        rowsPerPageOptions={DEFAULT_PAGE_SIZE_OPTIONS}
                        labelRowsPerPage="Show"
                        sx={(theme) => ({
                            fontSize: theme.typography.body2.fontSize,
                            '& .MuiTablePagination-displayedRows': {
                                fontSize: theme.typography.body2.fontSize,
                            },
                            '& .MuiToolbar-root': {
                                paddingLeft: theme.spacing(0),
                            },
                        })}
                        slotProps={{
                            select: {
                                IconComponent: ExpandMoreIcon,
                            },
                        }}
                    />
                    <WorkspaceSearch
                        setSearchTerm={handleSearch}
                        searchPlaceHolderValue={SEARCH}
                        automationId="ml-workspace-listing-search"
                    />
                </Stack>
                {content}
            </Grid>
        </Box>
    );
}
